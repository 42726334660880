import React from "react";
// import "./Menu.css";
// import Radium from 'radium'
import Radium from 'radium';
// import 'semantic-ui-css/semantic.min.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useAlert } from 'react-alert'
import { TransitionGroup } from "react-transition-group";
import './MenuBottom.css';





    const initStateBottom={
        bottom: -20 + 'px'
      }
      const finalStateBottom= {
        bottom: 0+'px'
      }
      const helloBottom=
      Radium.keyframes({
      '0%':initStateBottom,
      '100%': finalStateBottom
    })
    const menuStyleBottom =
         {
            width: 100 + '%',
            height: 6 + 'vh',
            background: "rgb(104, 179, 200)",
            position: 'absolute',
            // zIndex: 100,
            bottom: 0 + 'px',
        left: 0,
        animation: 'x 3s',
        animationName: helloBottom,
        display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    textAlign: 'left',
    // alignItems: 'center',
    listStyleType: 'none',
    // fontWeight: 'bold',
         
  //   '.menuItemRight':{
  //     color: '#000',
  //     textDecoration: 'blink',
  //     fontSize: 100 +'%',
  //     position: 'absolute',
  //     right: 29+'%',
  //     top: 0+'%',
  //     ':hover':{
  //        textDecoration: 'underline',
  //        fontWeight: 'bold'
  //        // fontSize: 16 +'px',
  //     }
  // },
  // '.menuItemLeft':{
  //  color: '#000',
  //  textDecoration: 'blink',
  //  fontSize: 100 +'%',
  //  position: 'absolute',
  //  left: 29+'%',
  //  top: 0+'%',
  //  ':hover':{
  //     textDecoration: 'underline',
  //     fontWeight: 'bold'
  //     // fontSize: 16 +'px',
  //  }
// }
        }
    //     const onSubmit = (e) =>{
    //       // e.preventDefault();
    //       // var title = this.title;
    //       // console.log(title.attributes.value);
    // // var email = title.attributes.value
    // // console.log(email)
    //       clipboard.writeText('astozicky@protonmail.com')
    
    //   }
// class MenuBottom extends Component {
  const MenuBottom = () => {
  
      const alert = useAlert()
 
 
    return (
    
    <div id="flyoutMenuBottom" style={menuStyleBottom}>
      <TransitionGroup></TransitionGroup>
        
        
        <CopyToClipboard text="astozicky@protonmail.com">
        <li id="ContactEmail" className="menuItem"><a className="copy-clipboard" href="#" key="3" id='menuItemLeftID' onClick={() => {alert.show('My email address is copied to your clipboard, drop me a line!')}} >Contact</a>  </li>
    </CopyToClipboard>
  
        {/* {copySuccess} */}
        {/* <textarea style={{}}    ref={textAreaRef}    /> */}
        {/* <h2 className="menuItem"><a key="4" style={menuStyleBottom[".menuItem"]} href="#" >Language</a></h2> */}
        <li  className="menuItem"><a key="4" id='menuItemRightID'  href="https://www.linkedin.com/in/adrienstozicky/"target="_blank" >Linkedin</a></li>
      <script>
      
          
  </script>
      
      </div>
    
      
    );
  }
  const MenuBottomFR = () => {
    
    
        const alert = useAlert()
   
   
      return (

      
      <div id="flyoutMenuBottom" style={menuStyleBottom}>
        <TransitionGroup></TransitionGroup>
          
      
          
          <CopyToClipboard text="astozicky@protonmail.com">
          {/* <li id="ContactEmail" className="menuItem"><a className="copy-clipboard" href="#" key="3" style={menuStyleBottom[".menuItemLeft"]} onClick={() => {alert.show('Mon adresse courriel est copiée dans votre presse-papiers, contactez-moi!')}} >Contact</a>  </li> */}
          <li id="ContactEmail" className="menuItem"><a className="copy-clipboard" href="#" key="3" id='menuItemLeftID' onClick={() => {alert.show('Mon adresse courriel est copiée dans votre presse-papiers, contactez-moi!')}} >Contact</a>  </li>
      </CopyToClipboard>
    
          {/* {copySuccess} */}
          {/* <textarea style={{}}    ref={textAreaRef}    /> */}
          {/* <h2 className="menuItem"><a key="4" style={menuStyleBottom[".menuItem"]} href="#" >Language</a></h2> */}
          <li  className="menuItem"><a key="4" id='menuItemRightID' href="https://www.linkedin.com/in/adrienstozicky/"target="_blank" >Linkedin</a></li>
        <script>
        
            
    </script>
        
        </div>
      
        
      );
    }
// }
const MenuBottomES = () => {


      const alert = useAlert()
 
 
    return (
 
    
    <div id="flyoutMenuBottom" style={menuStyleBottom}>
      <TransitionGroup></TransitionGroup>

        
        <CopyToClipboard text="astozicky@protonmail.com">
        <li id="ContactEmail" className="menuItem"><a className="copy-clipboard" href="#" key="3" id='menuItemLeftID' onClick={() => {alert.show('¡Mi dirección de correo electrónico está copiado en tu portapapeles, mándame un mensaje!')}} >Contacto</a>  </li>
    </CopyToClipboard>
        <li  className="menuItem"><a key="4" id='menuItemRightID'  href="https://www.linkedin.com/in/adrienstozicky/"target="_blank" >Linkedin</a></li>
      <script>
      
          
  </script>
      
      </div>
    
      
    );
  }


// export default Radium(CopyExample);
// export default Radium(MenuBottom);
// var RadiumMenuBottom = MenuBottom
// export default withRouter(RadiumMenuBottom)
export{MenuBottom,MenuBottomES,MenuBottomFR}