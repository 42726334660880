import React, {  useState, Suspense } from 'react'
import './Skills.css'
import useModal from './useModal'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom';
// import App from './App'
import {MenuTopwithRadiumFR} from './AppFR'
// import Modal from './Modal'
import { makeStyles } from '@material-ui/core/styles';
import Radium from 'radium';
import {  Icon } from 'semantic-ui-react'



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    height:"60vh",
    width:"96%",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(22.7),
      // height: theme.spacing(16),
      

    }
  }
}));
const Modal = React.lazy(() => import('./Modal'));
const SoftSkills = React.lazy(() => import('./Skills_SoftSkillsFR'))
const TechSkills = React.lazy(() => import('./Skills_TechSkillsFR'))
const SkillsFR = ({data,state,childRef, props,ref,adrien}) => {
  
const {isShowing, toggle} = useModal();
// const location = useLocation();
const[AccordionisShowing, setAccordionState] = useState(false);
const[AccordionSoftisShowing, setAccordionSoftState] = useState(false);

function Accordiontoggle() {
  setAccordionState(!AccordionisShowing);
  // console.log(AccordionisShowing)
}
function AccordionSofttoggle() {
  setAccordionSoftState(!AccordionSoftisShowing);
  // console.log(AccordionSoftisShowing)
}
const classes = useStyles();
  return(
<div className='rootSkills_AboutME'  >
  <MenuTopwithRadiumFR toggle={toggle} style={{backgroundColor:'#3f51b5'}}></MenuTopwithRadiumFR>
  <Suspense fallback={<div></div>}>
  <Modal  className="skillsModal" isShowing={isShowing} toggle={toggle}>

  <div className="Modal"><span>Faites votre choix</span></div>

           
          
           </Modal >
           </Suspense>
           <div className="Container">
           <div className="ButtonSkills" >
  <Button
   id='buttonTech'
          variant="contained"
          size="large"
          textSizeLarge
          className={classes.margin}
          // style={{width: 50+'%', position: 'relative',height:55+'px',backgroundColor:'#ebece9',fontSize:'x-large',color: '#05396b',fontWeight:'bold',border:'10px double #000'}}
          onClick={() => {if (AccordionSoftisShowing === true){
            AccordionSofttoggle()
            Accordiontoggle()
            
  
          }
        else if (AccordionSoftisShowing === false){
          Accordiontoggle()
        }
        }}
        
        
        >
          <Icon name='cogs' style={{transform: 'translate(-19%,-32%)',position:'relative'}}/>
          Compétences techniques
        </Button>
        <Button
         id='buttonTech'
          variant="contained"
          size="large"
          textSizeLarge
          className={classes.margin}
          // style={{width: 50+'%', position: 'relative',height:55+'px',backgroundColor:'#ebece9',fontSize:'x-large',color: '#05396b',fontWeight:'bold',border:'10px double #000'}}
        onClick={() => {if (AccordionisShowing === true){
          Accordiontoggle()
          AccordionSofttoggle()

        }
      else if (AccordionisShowing === false){
        AccordionSofttoggle()
      }
      }}
        >
          <Icon name='user' style={{transform: 'translate(-19%,-32%)',position:'relative'}}/>
          Compétences professionnelles
        </Button>
      
        </div>
        {/* <Link className='aboutMe' to={{pathname:"/AboutMe", state: {adrien:"true"} }} style={{width: 55+'%',height: 15+'%',transform: 'rotate(90deg) translate(26%, -775%)',position: 'fixed'}}> */}
        {/* <Link className='aboutMe' to={{pathname:"/fr/aboutme", state: {adrien:"true"} }} >
          <Button
          variant="contained"
          size="large"
          textSizeLarge
          // color="primary"
          className={classes.margin}
          style={{width: '100%',display:'inline-block',zIndex: 1,backgroundColor:'rgb(104, 179, 200)',border:'5px double #fff'}}
        >
          Qui suis-je?
        </Button>
        </Link> */}
           <div className="ContainerAccordion" 
          //  style={{width:90+'%',transform:'translate(1%,-80px)',position:'absolute',maxHeight: 100+'%'}}
           >

  <Link className='aboutMe' to={{pathname:"/fr/aboutme", state: {adrien:"true"} }} >
          <Button
          variant="contained"
          size="large"
          textSizeLarge
          id="buttonAboutMe"
          // color="primary"
          className={classes.margin}
          style={{width: '5vw',display:'inline-flex',zIndex: 1,backgroundColor:'rgb(104, 179, 200)',border:'5px double #fff',height:'55vh',fontSize:'2vw'}}
        >
          Qui suis-je?
        </Button>
        </Link>

        <div className={classes.root} id="relou"  style={(AccordionisShowing || AccordionSoftisShowing) ?{display: 'none'} :null}>
      <Paper elevation={0} id="myPaper" style={(AccordionisShowing || AccordionSoftisShowing) ?{display: 'none'} : {background:'rgb(235, 236, 233)',border: "10px solid #68b3c8"}}
>
<div className="rw-words rw-words-2" >
        <span>Node.JS</span>
        <span>Anglais</span>
        <span>Présentation</span>
        <span>CSS</span>
        <span>Français</span>
        <span>Freemarker</span>
        <span>Python</span>
        <span>Espagnol</span>
        <span>Api</span>
        </div>
              </Paper>
    </div>
    <Suspense fallback={<div></div>}>
<TechSkills AccordionisShowing={AccordionisShowing}></TechSkills>
</Suspense>
      <Suspense fallback={<div></div>}>
      <SoftSkills AccordionSoftisShowing={AccordionSoftisShowing} ></SoftSkills>
      </Suspense>
      </div>
  
      </div>
</div>
  )
}
export default Radium(SkillsFR);