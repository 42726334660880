// import Modal from './Modal'
import React, {useState,  forwardRef,Suspense } from 'react';
import './App.css';
// import {Slideshow, Modal} from  './Modal';
// import Modal from './Modal'
import useModal from './useModal'
// import useModal from './Modal';
// import Page from './Modal'
// import Radium from 'radium'
import Radium, { StyleRoot } from 'radium';
// import Menu from './Menu'
import {MenuBottom} from './MenuBottom'
import MouseTooltip from 'react-sticky-mouse-tooltip';
// import { useAlert } from 'react-alert'
import {Link } from 'react-router-dom';
// const alert = useAlert()
const initState={
  left: -1000 + 'px'

}
const finalState= {
  left: 0+'px'
}
const hello=
Radium.keyframes({
'0%':initState,
'100%': finalState
})
const menuStyleCentral =
   {
      width: 100 + '%',
      // background: "#61dafb",
      position: 'absolute',
      // zIndex: 100,
      left: 0 + 'px',
  animation: 'x 3s',
  animationName: hello,
  overflow: 'hidden',
  display: 'flex',
alignContent: 'center',
justifyContent: 'center',

   
   }
   const photoDiv = {
    // height: 250 +'px',
    // width: 'auto',
    // borderRadius: 100 +'%',
    // right: 281 +'px',
    // position: 'fixed',
    // bottom: 27 +'px',
    zIndex:2,
    position: 'absolute',
    top: 50+'%',
    left: 50+'%',
    mozTransform: 'translateX(-50%) translateY(-50%)',
    webkitTransform: 'translateX(-50%) translateY(-50%)',
    transform: 'translateX(-50%) translateY(-50%)',
    '@media only screen and (max-width:900px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-53%)',

    },
    '@media only screen and (max-width:960px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',

    },
    '@media only screen and (max-width:1280px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',

    },
    '@media only screen and (max-width:1500px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',

    }
   }

  
      const divSpanStyle= {
        display: 'none',
      }

  const MenuTop =({isShowing,toggle}) => {
    // const {isShowing, toggle} = useModal();

        const initState={
          top: -20 + 'px'
        }
        const finalState= {
          top: 0+'px'
        }
        const hello=
        Radium.keyframes({
        '0%':initState,
        '100%': finalState
      })
      const menuStyle =
           {
              width: 100 + '%',
              height: 6 + '%',
              background: "rgb(104, 179, 200)",
              position: 'absolute',
              // zIndex: 100,
              top: 0 + 'px',
          left: 0,
          animation: 'x 2s',
          animationName: hello,
          display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      textAlign: 'left',
      // alignItems: 'center',
      listStyleType: 'none',
      // fontWeight: 'bold',
      zIndex:1,
          }
        return(
          <div>
          {/* <div id="cover" style={menuStyle} > */}
          {/* </div> */}
        <div id="flyoutMenu" style={menuStyle} >
          
      <ul className="menuUL">
        <li className="menuItem" ><Link to={{pathname:"/", state: {isShowing: true} }} ><a key="1" id='menuItemLeftID' href="#" >Home</a></Link></li>
        <li className="menuItem" {...isShowing} onClick={toggle}><a key="4" id='menuItemRightID' href="#"   >Language selection</a></li>
        </ul>
      </div>
      </div>
        )
      }
      const Modal = React.lazy(() => import('./Modal'));
      // const App = props =>  {
        // const App = forwardRef((props, location,adrien,ref ) =>  {
          const App = forwardRef((props,ref ) =>  {


        const [ImageDivAnimation, setImageDivAnimation] = React.useState(0);
        const [flg,setFlg] = useState(false);
        const [clicked, setClicked] = useState(false)

  const {isShowing, toggle} = useModal();
  
    
        return (
       
          
         
          <div className="App" style={menuStyleCentral}>

                            {/* <div id="cover" style={menuStyle} >
</div> */}
               <header className="App-header">

      <MenuTopwithRadium isShowing={isShowing} toggle={toggle}></MenuTopwithRadium>
      <Suspense fallback={<div>Loading...</div>}>
      <Modal   isShowing={isShowing}  toggle={toggle}>
            {/* <div className="Modal" style={{color:'#f2aa4d', position:"relative", bottom:142 + 'px', borderRadius:10 + "px", background: "#111111"}}><span style={{color: "#c9c9ca"}}>Choose your language</span></div> */}
            <div className="Modal"><span>Choose your language</span></div>
           
          
           </Modal>
           </Suspense>
                         <StyleRoot>
             {/* <div className="divSquareRight" key="5" ImageDivAnimation={ImageDivAnimation}><span className="SpanRight" style={{color:'black',fontWeight:'bold',position:'absolute',right:200+'px',top:200+'px'}} key="6">Skills</span> */}
             <div className="divSquareRight" key="5" imagedivanimation={ImageDivAnimation}><Link to={{pathname:"/skills", state: {isShowing: true} }} className="linkRight"><button className="SpanRightEN" key="6">Skills</button></Link>
             </div>
             </StyleRoot>
          {/* <div key="6" className="divSquareLeft" ImageDivAnimation={ImageDivAnimation}><span style={{color:'black',fontWeight:'bold',position:'absolute',left:200+'px',top:200+'px'}} key="6">About me</span> */}
          <div key="6" className="divSquareLeft" imagedivanimation={ImageDivAnimation}><Link to={{pathname:"/aboutme", state: {adrien:"true"} }} className="linkLeft"><button className="SpanLeft" key="6">About me</button></Link>
              {/* <div style={{position:'absolute',top:0,right:0,left:0,bottom:0, background:'rgba(0,0,0,0.5)',
//   zIndex:0}}></div> */}
            </div> 

    
            <div className='photoDiv' style={photoDiv}>
             <img id='photo' src={require('./HomeImages/adrien.webp')} className='photo' />
            </div>
	<div className="rainbow">
  
</div>
{/* <div className="BlackFilter"></div> */}
        <div className="WelcomeDIV"
        // onClick={() =>  setClicked(true)}
        onMouseOver={() => {if (clicked === false)
        {setFlg(true)}}}
        onMouseOut={() => setFlg(false)}
                    // onMouseOut={() => this.setFlgOut(this.state.flg)}
        // onClick={() => this.myClick(!this.clicked)}
        onClick={() => setImageDivAnimation(1)}
        imagedivanimation={ImageDivAnimation}
          // style={this.state.clicked ? ImageDivStyleAnimation : ImageDivStyleAnimationInitial}
          >
            <div>
             <span style={clicked ?  divSpanStyle : null}>Welcome to my personal page!</span>
             <div>
                              {/* <MouseTooltip visible={!this.state.clicked && !this.state.flg} */}
                              <MouseTooltip visible={flg} style={{zIndex:100}}
           offsetX={-350}
          offsetY={-30}>     
                <span key='4'
                className="tooltiptext">Click me!</span>
              </MouseTooltip>
             
                       
                              <MouseTooltip visible={!clicked && !flg} 
        offsetX={-350}
        offsetY={-60}>     
              <span key='10' style={{color:"white",whiteSpace: 'nowrap'}}
              className="tooltiptext">Find the element to click!</span>
            </MouseTooltip>
            </div>
                         {/* <Modal show={this.state.modalToggle} modalClosed={this.modalHandler}> */}
            {/* <Modal show={modalToggle} modalClosed={() => setModaltoggle(false)}> */}
             
            </div>
            </div>
            {/* <Modal   isShowing={isShowing}  toggle={toggle}>
            {/* <div className="Modal" style={{color:'#f2aa4d', position:"relative", bottom:142 + 'px', borderRadius:10 + "px", background: "#111111"}}><span style={{color: "#c9c9ca"}}>Choose your language</span></div> */}
            {/* <div className="Modal"><span>Choose your language</span></div> */}
           
          
           {/* </Modal>  */}
           {/* </body> */}
          <StyleRoot>
           <MenuBottom ContactClick={() => {alert.show('Oh look, an alert!')}}></MenuBottom>
        </StyleRoot>
  
            </header>
          
            </div>
)
      })
  
  // export default App;
  var AppWithRadium = Radium(App)
  // export default Radium(App);
  export {AppWithRadium};

  var MenuTopwithRadium = Radium(MenuTop)
  export {MenuTopwithRadium}