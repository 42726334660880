import React, {Suspense} from 'react'
import './AboutMe.css'
import useModal from './useModal'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
// import App from './App'
import {MenuTopwithRadium} from './App'
// import Modal from './Modal'
import { makeStyles } from '@material-ui/core/styles';
import Radium from 'radium';





const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  }
}));

const Modal = React.lazy(() => import('./Modal'));
const AboutMe = ({data,Skills,state,childRef, props,ref,adrien}) => {
  

const {isShowing, toggle} = useModal();

const classes = useStyles();
  return(
 

//  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
<div className='rootAboutME'>
  <MenuTopwithRadium toggle={toggle} style={{backgroundColor:'#3f51b5'}}></MenuTopwithRadium>
  <Suspense fallback={<div></div>}>
  <Modal   isShowing={isShowing} toggle={toggle}>
  <div className="Modal"><span>Choose your language</span></div>

           </Modal >
           </Suspense>
       
<div id="Container">
<div className="divPhoto">
              <img id='photoAboutMe' src={require('./HomeImages/adrien.webp')} className='photo' />
              
            <div className="wrapper" data-anim="base wrapper">
  <div className="circle1" data-anim="base left"></div>
  <div className="circle2" data-anim="base right"></div>
  </div>

</div>
<div id="ContainerTextandButton">
<Link id="Skills" to={{pathname:"/Skills", state: {adrien:"true"} }} >
          <Button
          id="SkillsButton"
          variant="contained"
          size="large"
          textSizeLarge
          // color="primary"
          className={classes.margin}
          // style={{width: 'inherit',zIndex: 1,display:'inline-block',backgroundColor:'rgb(104, 179, 200)',fontSize:'x-large',border:'5px double #fff'}}
          // style={{display:"inline-flex"}}
        >
          Skills
        </Button>
        </Link>
        

        {/* <div className='photoDiv' style={photoDiv}>
             <img id='photo' src={require('./HomeImages/adrien.jpg')} className='photo' style={{zIndex:1}} />
            </div> */}

<div className="text"><h1 className="spanText"> 
  About me<br></br>
  </h1><p class="aboutMEText" >Oddly enough  I didn't study computer science or anything IT related but History and languages. And here I am working as a Technical consultant and already boasting more than 6 years and a half of experience in the IT field. You could say that life moves in mysterious ways, but I think there's nothing surprising as I've been interested in computers for a long time. I'm using Linux since my teenage years and after all programming is reading and writing a language as I once heard Linus Torvalds say (If you don't know who is Linus Torvalds, look it up right now, not when you're done reading, now!). Fake humorous indignations aside computers talk a language and I like languages a lot, I work and interact everyday with customers in French, English and Spanish. In my 6 years and a half in the industry I've successively been a Service Desk coordinator, a Presales consultant and a Technical Consultant, position I am currently holding. I am working closely with Presales Consultant and providing them with technical support. I think I understand well the challenges of their job as I worked myself as a Presales Consultant four years and I try every day to strike the best balance I can between my Technical Expertise and the ultimate goal of my job, selling. In other words if I won't hesitate to tell customers truths like "we can't do that" I will also try my best to either find a workaround or get commitment from Product Management that we're going to develop it. That's the professional me in a nutshell, if you want to learn more about my qualifications you click on the Skills button on the right-side of the screen.</p></div>
  </div>
  </div>
</div>

  )
}
export default Radium(AboutMe);