// import Modal from './Modal'
import React, {useState,  forwardRef,Suspense } from 'react';
import './App.css';
// import {Slideshow, Modal} from  './Modal';
// import Modal from './Modal'
import useModal from './useModal'
// import useModal from './Modal';
// import Page from './Modal'
// import Radium from 'radium'
import Radium, { StyleRoot } from 'radium';
// import Menu from './Menu'
import {MenuBottomES} from './MenuBottom'
import MouseTooltip from 'react-sticky-mouse-tooltip';
// import { useAlert } from 'react-alert'
import {Link } from 'react-router-dom';
// const alert = useAlert()
const initState={
  left: -1000 + 'px'

}
const finalState= {
  left: 0+'px'
}
const hello=
Radium.keyframes({
'0%':initState,
'100%': finalState
})
const menuStyleCentral =
   {
      width: 100 + '%',
      // background: "#61dafb",
      position: 'absolute',
      // zIndex: 100,
      left: 0 + 'px',
  animation: 'x 3s',
  animationName: hello,
  overflow: 'hidden',
  display: 'flex',
alignContent: 'center',
justifyContent: 'center',

   
   }
   const photoDiv = {
    // height: 250 +'px',
    // width: 'auto',
    // borderRadius: 100 +'%',
    // right: 281 +'px',
    // position: 'fixed',
    // bottom: 27 +'px',
    zIndex:2,
    position: 'absolute',
    top: 50+'%',
    left: 50+'%',
    mozTransform: 'translateX(-50%) translateY(-50%)',
    webkitTransform: 'translateX(-50%) translateY(-50%)',
    transform: 'translateX(-50%) translateY(-50%)',
    '@media only screen and (max-width:900px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-53%)',

    },
    '@media only screen and (max-width:960px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',

    },
    '@media only screen and (max-width:1280px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',

    },
    '@media only screen and (max-width:1500px)':{
      zIndex:2,
      position: 'absolute',
      top: 50+'%',
      left: 50+'%',
      mozTransform: 'translateX(-50%) translateY(-50%)',
      webkitTransform: 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',

    }
   }
  //  const photo = {
  //   height: 330 +'px',
  //   width: 'auto',
  //   borderRadius: 100 +'%',
  //   // right: 281 +'px',
  //   // position: 'absolute',
  //   // bottom: 27 +'px',
  //   // zIndex:1,
  //   '@media only screen and (min-width:800px)':{
  //     height: 300 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:960px)':{
  //     height: 330 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:1050px)':{
  //     height: 370 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:1150px)':{
  //     height: 400 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:1280px)':{
  //     height: 440 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:1400px)':{
  //     height: 480 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:1500px)':{
  //     height: 550 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:1900px)':{
  //     height: 660 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   },
  //   '@media only screen and (min-width:2500px)':{
  //     height: 890 +'px',
  //     width: 'auto',
  //     borderRadius: 100 +'%',
  //   }
  //  }
 
//    const initStateRightElement={
//     right: -2000 + 'px'
//   }
//   const finalStateRightElement= {
//     right: -451+'px'
//   }
//   const RightElement=
//   Radium.keyframes({
//   '0%':initStateRightElement,
//   '100%': finalStateRightElement
// })
//    const divSquareRight={
 
//     // shapeOutside: 'circle(50%)',
//     width: 440+'px',
//     height: 328+'px',
//     // left: 255+'px',
//     right: -451+'px',
//     bottom: 0 +'px',
//     background: 'radial-gradient(circle at left, transparent 34%, #4daec8 15px)',
//     position: 'relative',
//     float: 'left',
//     // -moz-radial-gradient'(0 50%, circle, rgba(204,0,0,0) 14px, #c00 15px)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center', 
//     animation: 'x 1s',
//     animationName: RightElement,
    
//     ':hover':{
//       background: 'radial-gradient(circle at left, transparent 34%, #5EB6CD 15px)',

//     },
//   }
//   const initStateLeftElement={
//     left: -2000 + 'px'
//   }
//   const finalStateLeftElement= {
//     left: -420+'px'
//   }
//   const LeftElementAnimation=
//   Radium.keyframes({
//   '0%':initStateLeftElement,
//   '100%': finalStateLeftElement
// })
//   const divSquareLeft={
 
//     // shapeOutside: 'circle(50%)',
//     width: 440+'px',
//     height: 328+'px',
//     // left: 255+'px',
//     left: -420+'px',
//     bottom: 0 +'px',
//     background: 'radial-gradient(circle at right, transparent 34%, #4daec8 15px)',
//     position: 'relative',
//     float: 'left',
//     // -moz-radial-gradient'(0 50%, circle, rgba(204,0,0,0) 14px, #c00 15px)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center', 
//     animation: 'x 1s',
//     animationName: LeftElementAnimation,
    
//     ':hover':{
//       background: 'radial-gradient(circle at right, transparent 34%, #5EB6CD 15px)',

//     },
//   }

  
      const divSpanStyle= {
        display: 'none',
      }

  const MenuTop =({isShowing,toggle}) => {
    // const {isShowing, toggle} = useModal();

        const initState={
          top: -20 + 'px'
        }
        const finalState= {
          top: 0+'px'
        }
        const hello=
        Radium.keyframes({
        '0%':initState,
        '100%': finalState
      })
      const menuStyle =
           {
              width: 100 + '%',
              height: 6 + 'vh',
              background: "rgb(104, 179, 200)",
              position: 'absolute',
              // zIndex: 100,
              top: 0 + 'px',
          left: 0,
          animation: 'x 2s',
          animationName: hello,
          display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      textAlign: 'left',
      // alignItems: 'center',
      listStyleType: 'none',
      // fontWeight: 'bold',
      zIndex:1
           ,
           
         
          }
        return(
          <div>
          <div id="cover" style={menuStyle} >
          </div>
        <div id="flyoutMenu" style={menuStyle} >
          
      
        <ul className="menuUL">
        <li className="menuItem" ><Link to={{pathname:"/es", state: {isShowing: true} }} ><a key="1" id='menuItemLeftID' href="#" >Página inicial</a></Link></li>
        <li className="menuItem" {...isShowing} onClick={toggle}><a key="4" id='menuItemRightID' href="#">Elección de idioma</a></li>
        </ul>
      </div>
      </div>
        )
      }
      const Modal = React.lazy(() => import('./Modal'));
      // const App = props =>  {
        // const App = forwardRef((props, location,adrien,ref ) =>  {
          const App = forwardRef((props,ref ) =>  {


        const [ImageDivAnimation, setImageDivAnimation] = React.useState(0);
        const [flg,setFlg, setFlgOut] = useState(false);
        const [clicked, setClicked] = useState(false)
      //   const initStateLeftElement={
      //     left: -2000 + 'px'
      //   }
      //   const finalStateLeftElement= {
      //     left: -420+'px'
      //   }
      //   const LeftElementAnimation=
      //   Radium.keyframes({
      //   '0%':initStateLeftElement,
      //   '100%': finalStateLeftElement
      // })
      //   const divSquareLeft={
       
      //     width: 650+'px',
      //     height: 442+'px',
      //     left: -10+'px',
      //     bottom: 34 +'px',
      //     background: 'radial-gradient(circle at right, transparent 32%, #4daec8 15px)',
      //     position: 'absolute',
      //     float: 'left',
      //     // -moz-radial-gradient'(0 50%, circle, rgba(204,0,0,0) 14px, #c00 15px)',
      //     display: 'block', 
      //     animation: 'x 1s',
      //     animationName: LeftElementAnimation,
          
      //     ':hover':{
      //       background: 'radial-gradient(circle at right, transparent 32%, #5EB6CD 15px)',
      
      //     },
      //   }
        // const [modalToggle, setModaltoggle] = useState(false)
        // const [profileState, setProfileState] = useState(props);

        // const [profileState, setProfileState] = useState(props);
        // console.log(profileState, props)
        // const {LanguageClick} = props
  //   setFlgOut = () =>
//   {
//     this.setState({
//       flg:false
//     })
// //   }
// function toggleModal () {
//   setModaltoggle(true)
//   console.log("true")
  
// }
// function toggleModalClose () {
//   setModaltoggle(false)
//   console.log("true")
  
// }
  // modalHandler = (e) => {
  //   e.preventDefault(); //i added this to prevent the default behavior
  //   this.setState({
  //     modalToggle: !this.state.modalToggle
      
  //   })}

  const {isShowing, toggle} = useModal();
    
  

  // useImperativeHandle(ref,  ()=> ({
  //   getAlert() {
  //     alert(isShowing);
  //   }
  
  // }));
        return (
       
          
         
          <div className="App" style={menuStyleCentral}>

                            {/* <div id="cover" style={menuStyle} >
</div> */}
               <header className="App-header">
      <MenuTopwithRadiumES isShowing={isShowing} toggle={toggle}></MenuTopwithRadiumES>
      <Suspense fallback={<div>Loading...</div>}>
      <Modal   isShowing={isShowing}  toggle={toggle}>
            {/* <div className="Modal" style={{color:'#f2aa4d', position:"relative", bottom:142 + 'px', borderRadius:10 + "px", background: "#111111"}}><span style={{color: "#c9c9ca"}}>Choose your language</span></div> */}
            {/* <div className="Modal"><span>Choose your language</span></div> */}
            <span className="Modal">Elegi tu idioma</span>
          
           </Modal>
           </Suspense>

            {/* <button id='buttonRef' style={{left: 100+'px',position: 'absolute'}} onClick={() => childRef.current.getAlert()}>Click</button> */}
            
            {/* </StyleRoot>
                         {/* <div key="5" style={divSquare}><img src={require('./Road.jpg')}/></div> */}
                         
      {/* <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={{ enter: 300, exit: 300 }}
          classNames={'fade'}
        > */}
                         <StyleRoot>
             {/* <div className="divSquareRight" key="5" ImageDivAnimation={ImageDivAnimation}><span className="SpanRight" style={{color:'black',fontWeight:'bold',position:'absolute',right:200+'px',top:200+'px'}} key="6">Skills</span> */}
             <div className="divSquareRight" key="5" ImageDivAnimation={ImageDivAnimation}><Link to={{pathname:"/es/skills", state: {isShowing: true} }} className="linkRight" ><button className="SpanRight" key="6">Habilidades</button></Link>
           {/* <div style={{position:'absolute',top:0,right:0,left:0,bottom:0, background:'rgba(0,0,0,0.5)',
//   zIndex:0}}></div> */}
             </div>
             </StyleRoot>
          {/* <div key="6" className="divSquareLeft" ImageDivAnimation={ImageDivAnimation}><span style={{color:'black',fontWeight:'bold',position:'absolute',left:200+'px',top:200+'px'}} key="6">About me</span> */}
          <div key="6" className="divSquareLeft" ImageDivAnimation={ImageDivAnimation}><Link to={{pathname:"/es/aboutme", state: {adrien:"true"} }} className="linkLeft"><button className="SpanLeft" key="6">¿Quién soy?</button></Link>
              {/* <div style={{position:'absolute',top:0,right:0,left:0,bottom:0, background:'rgba(0,0,0,0.5)',
//   zIndex:0}}></div> */}
            </div> 
            {/* </CSSTransition>
      </TransitionGroup> */}
    
            <div className='photoDiv' style={photoDiv}>
             <img id='photo' src={require('./HomeImages/adrien.webp')} className='photo' />
            </div>
	<div className="rainbow">
  
</div>
{/* <div className="BlackFilter"></div> */}
        <div className="WelcomeDIV"
        // onClick={() =>  setClicked(true)}
        onMouseOver={() => {if (clicked === false)
        {setFlg(true)}}}
        onMouseOut={() => setFlg(false)}
                    // onMouseOut={() => this.setFlgOut(this.state.flg)}
        // onClick={() => this.myClick(!this.clicked)}
        onClick={() => setImageDivAnimation(1)}
        ImageDivAnimation={ImageDivAnimation}
          // style={this.state.clicked ? ImageDivStyleAnimation : ImageDivStyleAnimationInitial}
          >
            <div>
             <span style={clicked ?  divSpanStyle : null}>Bienvenido a mi pagina personal!</span>
             <div>
                              {/* <MouseTooltip visible={!this.state.clicked && !this.state.flg} */}
                              <MouseTooltip visible={flg} style={{zIndex:100}}
           offsetX={-350}
          offsetY={-30}>     
                <span key='4'
                className="tooltiptext">Haz clic aquí para descubrir más!</span>
              </MouseTooltip>
             
                       
                              <MouseTooltip visible={!clicked && !flg} 
        offsetX={-350}
        offsetY={-60}>     
              <span key='10' style={{color:"white",whiteSpace: 'nowrap'}}
              className="tooltiptext">Encuentra el elemento para hacer click!</span>
            </MouseTooltip>
            </div>
                         {/* <Modal show={this.state.modalToggle} modalClosed={this.modalHandler}> */}
            {/* <Modal show={modalToggle} modalClosed={() => setModaltoggle(false)}> */}
             
            </div>
            </div>
            {/* <Modal   isShowing={isShowing}  toggle={toggle}>
            {/* <div className="Modal" style={{color:'#f2aa4d', position:"relative", bottom:142 + 'px', borderRadius:10 + "px", background: "#111111"}}><span style={{color: "#c9c9ca"}}>Choose your language</span></div> */}
            {/* <div className="Modal"><span>Choose your language</span></div> */}
           
          
           {/* </Modal>  */}
           {/* </body> */}
          <StyleRoot>
           <MenuBottomES ContactClick={() => {alert.show('Oh look, an alert!')}}></MenuBottomES>
        </StyleRoot>
  
            </header>
          
            </div>
)
      })
  
  // export default App;
  var AppWithRadiumES = Radium(App)
  // export default Radium(App);
  export {AppWithRadiumES};

  var MenuTopwithRadiumES = Radium(MenuTop)
  export {MenuTopwithRadiumES}