import React, {Suspense} from 'react'
import './AboutMe.css'
import useModal from './useModal'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
// import App from './App'
import {MenuTopwithRadiumFR} from './AppFR'
// import Modal from './Modal'
import { makeStyles } from '@material-ui/core/styles';
import Radium from 'radium';





const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  }
}));
const Modal = React.lazy(() => import('./Modal'));

const AboutMeFR = ({data,Skills,state,childRef, props,ref,adrien}) => {
  

const {isShowing, toggle} = useModal();

const classes = useStyles();
  return(
 

//  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
<div className='rootAboutME'  >
  <MenuTopwithRadiumFR toggle={toggle} style={{backgroundColor:'#3f51b5'}}></MenuTopwithRadiumFR>
  <Suspense fallback={<div></div>}>
  <Modal   isShowing={isShowing} toggle={toggle}>
  <div className="Modal"><span>Faites votre choix</span></div>


           </Modal >
</Suspense>
<div id="Container">
<div className="divPhoto">
              <img id='photoAboutMe' src={require('./HomeImages/adrien.webp')} className='photo' />
              
            <div className="wrapper" data-anim="base wrapper">
  <div className="circle1" data-anim="base left"></div>
  <div className="circle2" data-anim="base right"></div>
  </div>

</div>
<div id="ContainerTextandButton">
<Link id="Skills" to={{pathname:"/fr/skills", state: {adrien:"true"} }} >
          <Button
          id="SkillsButton"
          variant="contained"
          size="large"
          textSizeLarge
          // color="primary"
          className={classes.margin}
          // style={{width: 'inherit',zIndex: 1,display:'inline-block',backgroundColor:'rgb(104, 179, 200)',fontSize:'x-large',border:'5px double #fff'}}
        >
          Qualifications
        </Button>
        </Link>
        

        {/* <div className='photoDiv' style={photoDiv}>
             <img id='photo' src={require('./HomeImages/adrien.jpg')} className='photo' style={{zIndex:1}} />
            </div> */}

<div className="text"><h1 className="spanText"> 
  Qui suis-je?<br></br>
  </h1><p class="aboutMEText">Curieusement je n’ai pas étudié la science informatique ni quoi que ce soit d’approchant mais l’Histoire et la linguistique. Et me voici travaillant comme Consultant technique et comptant déjà plus de 6 ans et demi d’expérience dans le domaine de l’informatique. On pourrait penser que mon parcours est surprenant mais je pense qu’il est plutôt logique, en effet je m’intéresse aux ordinateurs depuis longtemps. J’utilise Linux depuis l’adolescence et en fin de compte programmer c’est lire et écrire un langage comme le dit un jour Linus Torvalds (si vous ne savez pas qui est Linus Torvalds, recherchez son nom maintenant, pas quand vous aurez fini de lire ce texte, maintenant !). Fausses indignations humoristiques à part les ordinateurs parlent un langage et j’aime beaucoup les langues, je travaille et j’interagis quotidiennement avec mes clients en Français, Anglais et Espagnol. En 6 ans et demi dans l’industrie informatique j’ai été successivement coordinateur de service informatique, consultant avant-vente et consultant technique, mon poste actuel. Je travaille étroitement avec les consultants avant-ventes et je suis en charge de leur support technique. Je pense bien comprendre les défis de leur poste parce que j’ai moi-même travaillé comme consultant avant-vente pendant quatre années et j’essaye chaque jour de trouver le meilleur équilibre entre mon expertise technique et le but ultime de mon poste, vendre. En d’autres termes si je n’hésite pas à dire aux clients des vérités comme « ça on ne peut pas le faire » j’essaye toujours de trouver soit une solution alternative soit d’obtenir l’engagement de l’équipe produit que nous allons développer la fonctionnalité. Voilà, c’est en résumé ce que je fais. Si vous voulez en savoir plus sur mes compétences vous pouvez cliquer sur le bouton Qualifications du côté droit de l’écran.</p></div>
</div>
</div>
</div>

  )
}
export default Radium(AboutMeFR);