// import React, { Component } from 'react';
// import "./index.css";
// import mp3 from './Mexico_Jarabe.mp3';
import React from 'react';
// import Modal from 'react-bootstrap/Modal';
// import App from './App'
import {AppWithRadium} from './App'
import {AppWithRadiumES} from './AppES'
// import AppES from './AppES'
import Columns from './Skills'
import SkillsFR from './SkillsFR'
import SkillsES from './SkillsES'
import './index.css';
import { StyleRoot } from 'radium';
import AlertTemplate from 'react-alert-template-basic'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {AppWithRadiumFR} from './AppFR';
// import { render } from '@testing-library/react';
import { render } from 'react-dom';
import AboutMe from './AboutMe';
import AboutMeFR from './AboutMeFR'
import AboutMeES from './AboutMeES'




const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

// ReactDOM.render(
//   <React.StrictMode>
//       <AlertProvider template={AlertTemplate} {...options}>
//     <StyleRoot>
      
//     <Router>
//           <Switch>
//           <Route path='/es' exact component={AppES}/>
//           <Route path='/' exact component={App}/>
//           <Route path='/en' exact component={App}/>
//           <Route path='/fr' exact component={AppFR}/>
//           </Switch>
//           </Router>
  
//     </StyleRoot>
//     </AlertProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
// serviceWorker.unregister();


const Index =({data,Skills,props}) =>(
  // <div>

  <React.StrictMode>

    <Router>
      <AlertProvider template={AlertTemplate} {...options}>
    <StyleRoot>

    
          <Switch>
          <Route path='/es' exact component={AppWithRadiumES}/>
          <Route path='/' exact component={AppWithRadium}/>
          <Route path='/en' exact component={AppWithRadium}/>
          <Route path='/fr' exact component={AppWithRadiumFR}/>
          <Route  path='/skills' exact component={Columns}/>
          <Route  path='/fr/skills' exact component={SkillsFR}/>
          <Route  path='/es/skills' exact component={SkillsES}/>
          <Route {...props}  path='/aboutMe' exact component={AboutMe}/>
          <Route {...props}  path='/fr/aboutMe' exact component={AboutMeFR}/>
          <Route {...props}  path='/es/aboutMe' exact component={AboutMeES}/>
          </Switch>
        
    </StyleRoot>
    </AlertProvider>
    </Router>
  
  </React.StrictMode>
    

    // </div>
);
render(<Index/>,document.getElementById('root'))

// const myfirstelement = <h1>Hello React!</h1>
// ReactDOM.render(myfirstelement, document.getElementById('root'));

// class Page extends React.Component{
  
//   constructor(props) {
//     super(props);
//     this.componentDidMount = this.componentDidMount.bind(this);
//     this.componentDidMount2 = this.componentDidMount2.bind(this);
//     this.showSlides = this.showSlides.bind(this)
//     this.slideIndex = 0;
//     this.toggleModal = this.toggleModal.bind(this)
//     this.state = {
//       open: false
//     }
//   }

//   componentDidMount(onMouseOver) {
    
//     this.audio = new Audio(mp3)
//     this.audio.load()
//     if(onMouseOver){
//     this.audio.play()
//     }
//   }
// componentDidMount2() {
    
//   this.audio.pause()
// }

// showSlides() {

//   var i;
//   var slides = document.getElementsByClassName("mySlides");

//   for (i = 0; i < slides.length; i++) {
//     slides[i].style.display = "none";  
//   }

//   this.slideIndex++;
  
//   if (this.slideIndex > slides.length) {this.slideIndex = 1}

//   slides[this.slideIndex-1].style.display = "block";  
//   setTimeout(showSlides, 3000); // Change image every 2 seconds

 
// }
// EventsandTriggers(event) {
//   this.modal = document.querySelector(".modal");
//   var trigger = document.querySelector(".trigger");
//   var closeButton = document.querySelector(".close-button");
//   trigger.addEventListener("click", this.toggleModal);
//   closeButton.addEventListener("click", this.toggleModal());
//   window.addEventListener("click", this.windowOnClick);
    // if (event.target === modal) {
    //   modal.classList.toggle("show-modal");
  
    // }
  // }
// toggleModal() {
//   this.modal = document.querySelector(".modal");
//   this.modal.classList.toggle("show-modal");
//   }
  // windowOnClick(event) {
  //   if (event.target === this.modal) {
  //       this.toggleModal();
  //   }
// }



//       render(){
//           return(   
//               <div className="head">
//               <div>
//               <button className="trigger" onClick={this.toggleModal} >Click here to trigger the modal!</button>
//               { /* all the markup of your page */ }
// {/* <button className="trigger" onClick={() => this.setState({Modal: true})}>Add Work Log</button> */}
// { /* anything else */ }

// { /* modal is here but it is hidden */ }
// {/* <Modal open={this.state.showModal}>
// <h5>Please choose your preferred language!</h5>
// </Modal> */}
//               </div>
//               <div className="modal">
//               <div className="modal-content">
//               <span className="close-button">&times;</span>
//               <h5>Please choose your preferred language!</h5>
//               <label style={{top:-40 + 'px',position:'relative'}}>Español</label>
//               <button id="languageButtonPopup" className="languageButton"  onMouseOver={onMouseOver => this.componentDidMount(onMouseOver)} onMouseOut={this.componentDidMount2} text="General"style={{height:50 + 'px'}} />
//               <div style={{maxHeight: 150 + 'vh', overflow: 'hidden'}}>
//               <div className="slideshow-container">
//               <div className="mySlides fade">
//                   <img src= {require('./mexico-flag-round-icon-64.png')} style={{width:30 + 'px',height:30 + 'px'}}/>
//                   </div>
//                   <div className="mySlides fade">
//                         <img src={require('./mexico-calavera.png')} style={{width:30 + 'px',height:30 + 'px'}}/>
//                     </div>
//                     <div className="mySlides fade">
//                         <img src={require('./carlos.png')} style={{width:30 + 'px',height:30 + 'px'}}/>
//                     </div>
//                 </div>
                
//                 </div>
//               </div>
//               </div>
           
//               <div className="AudioDIV">
        
//               </div>            
              
//                     </div>      
//           )
//           }
//         }
// class showSlides extends React.Component{
//           constructor(props){
//             super(props)
//           this.showSlides = this.showSlides.bind(this)
//           this.slideIndex = 0
        
//           };
        

//         }


    // ReactDOM.render(
    //     <App />,
    //     document.getElementById('root')
    // );

  