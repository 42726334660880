import React , {Suspense} from 'react'
import './AboutMe.css'
import useModal from './useModal'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
// import App from './App'
import {MenuTopwithRadiumES} from './AppES'
// import Modal from './Modal'
import { makeStyles } from '@material-ui/core/styles';
import Radium from 'radium';





const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  }
}));
const Modal = React.lazy(() => import('./Modal'));

const AboutMeES = ({data,Skills,state,childRef, props,ref,adrien}) => {
  

const {isShowing, toggle} = useModal();

const classes = useStyles();
  return(
 

//  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
<div className='rootAboutME'  >
  <MenuTopwithRadiumES toggle={toggle} style={{backgroundColor:'#3f51b5'}}></MenuTopwithRadiumES>
  <Suspense fallback={<div></div>}>
  <Modal   isShowing={isShowing} toggle={toggle}>
  <span className="Modal">Elegi tu idioma</span>

           </Modal >
           </Suspense>
           <div id="Container">
           <div className="divPhoto">
              <img id='photoAboutMe' src={require('./HomeImages/adrien.webp')} className='photo' />
              
            <div className="wrapper" data-anim="base wrapper">
  <div className="circle1" data-anim="base left"></div>
  <div className="circle2" data-anim="base right"></div>
  </div>

</div>
<div id="ContainerTextandButton">
<Link id="Skills" to={{pathname:"/es/skills", state: {adrien:"true"} }} >
          <Button
          id="SkillsButton"
          variant="contained"
          size="large"
          textSizeLarge
          // color="primary"
          className={classes.margin}
          // style={{width: 'inherit',zIndex: 1,display:'inline-block',backgroundColor:'rgb(104, 179, 200)',fontSize:'x-large',border:'5px double #fff'}}
        >
          Habilidades
        </Button>
        </Link>
        

        {/* <div className='photoDiv' style={photoDiv}>
             <img id='photo' src={require('./HomeImages/adrien.jpg')} className='photo' style={{zIndex:1}} />
            </div> */}
   
<div className="text"><h1 className="spanText"> 
¿Quién soy?<br></br>
  </h1><p class="aboutMEText">Curiosamente no estudié informática, sino Historia y lenguas. Y aquí estoy trabajando como Consultor técnico con más de 6 años y medio de experiencia en el campo informático. Se podría decir que la vida está llena de sorpresas, pero pienso que no haya nada sorprendente puesto que hace mucho tiempo que me apasiona la informática. Utilizo Linux desde mi adolescencia y, al fin y al cabo, programar es leer y escribir un lenguaje como lo dijo una vez Linus Torvalds (¡si no saben quién es Linus Torvalds, busquenlo ahora, no una vez leído este texto, ahora!). Falsas indignaciones humorísticas aparte, los ordenadores hablan un lenguaje y me gustan las lenguas, trabajo e interactúo con clientes en francés, inglés y español cotidianamente. A lo largo de mis seis años en la industria he trabajado sucesivamente como coordinador de servicio técnico, consultor de Preventa y consultor técnico, puesto que ocupo ahora. Trabajo estrechamente con consultores de preventa y les apoyo técnicamente. Pienso que entiendo bien los desafíos de su trabajo puesto que trabajé yo mismo cuatro años en calidad de consultor de preventa e intento cada día encontrar el equilibrio entre pericia técnica y la meta de mi trabajo: vender. En otras palabras, al igual que no dudo en decirles a los clientes verdades como “no lo podemos hacer” intento, dentro de las posibilidades, encontrar una solución alternativa o que el equipo producto se comprometa a desarrollar la funcionalidad que necesiten. Es lo que hago en breve, si quieren aprender más, hagan clic en el botón habilidades en la parte derecha de la pantalla.</p></div>
</div>
</div>
</div>

  )
}
export default Radium(AboutMeES);