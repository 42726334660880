import { useState } from 'react';

// const State = forwardRef((props, ref) =>{
//     const [initialState, setInitialState] = useState({
//         hoverState: false,
//         clickState: false
//       });
//       ref={ref}
//       return {
//         initialState,
//       } 
// })

const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);
    const [isShowing2, setIsShowing2] = useState(false);
 

    // const childRef = useRef();
    // const [isShowing, setIsShowing] = useState({
    //     hover: hoverState,
    //     click: clickState
    //     });
    
   

    function toggle() {
      setIsShowing(!isShowing);
      // console.log(isShowing)
    }
    function toggle2() {
        setIsShowing2(!isShowing2);
        // console.log('toggle2',isShowing2)
      }
    // const lastProps = useRef({
    //     hoverState, 
    //     clickState,
    //   });
    // function toggle(lastProps) {
        // console.log('togglecreateRef',childRef)
        // console.log('toggleInitial', initialState)
    //     if(hoverState === false){
    //     setIsShowing((previsShowing) => ({hover: true,
    //         click: 'true'}))
    //     console.log('toggle', isShowing)
    //   }
    //   else if (hoverState === true){
    //     setIsShowing((previsShowing) => ({hover: false,
    //         click: 'true'}))
    //         console.log('toggleElse', isShowing)
        
    //   }
    // }
    return {
      isShowing,
      toggle,
      isShowing2,
      toggle2
    }
}
export default useModal;
// export default withRouter(useModal)